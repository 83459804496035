/*
 |-----------------------------------------------------------------------------
 | utils/images.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { slice, split } from 'lodash';

/**
 * Some description...
 *
 * @param width
 */
const ratio = (width: number) => {
	return (1440 / 2560) * width;
};

/**
 * Some description...
 *
 * @param url
 */
const tinyUrl = (url: string) => {
	const parts = split(url, '/');

	return `/${slice(parts, 7).join('/')}`;
};

/**
 * Some description...
 *
 * @param url
 * @param transformations
 */
const transform = (url: string, transformations: string) => {
	const parts = split(url, 'upload');

	return `${parts[0]}upload/${transformations}${parts[1]}`;
};

export { ratio, tinyUrl, transform };
