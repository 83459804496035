/*
 |-----------------------------------------------------------------------------
 | components/vendor/Aos/Aos.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useEffect } from 'react';

import 'aos/dist/aos.css';
import AOS from 'aos';

import * as IAos from './types';

const Aos: FC<IAos.IProps> = ({ children }) => {
	useEffect(() => {
		AOS.init();
	}, []);

	return <>{children}</>;
};

export default Aos;
