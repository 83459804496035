/*
 |-----------------------------------------------------------------------------
 | components/molecules/SkipLink/SkipLink.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC } from 'react';

// import { ray } from 'node-ray/web';

import * as ISkipLink from './types';

const SkipLink: FC<ISkipLink.IProps> = ({ darkMode, storybook }) => {
	// ray('Debug molecule SkipLink:', {
	// 	darkMode: darkMode,
	// 	storybook: storybook,
	// }).blue();

	return (
		<a
			className={`
				block
				rounded-b
				text-center
				text-lg
				${
					darkMode
						? 'bg-neutral-100 text-neutral-900'
						: 'bg-neutral-900 text-neutral-100'
				}
				${
					storybook
						? `
						mx-auto
						p-4
						w-[375px]
					`
						: `
						sr-only
						focus:mx-auto
						focus:not-sr-only
						focus:p-4
						focus:w-[375px]
					`
				}
			`}
			data-testid="skipLink"
			href="#main-content"
		>
			Skip to main content
		</a>
	);
};

export default SkipLink;
