/*
 |-----------------------------------------------------------------------------
 | utils/schema.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { forEach, get } from 'lodash';
import { enGB } from 'date-fns/locale';
import { format } from 'date-fns';

/**
 * Some description...
 *
 * @param addresses
 */
const getAddress = (addresses: Array<object>) => {
	const store = [];

	if (addresses !== undefined) {
		forEach(addresses, address => {
			store.push({
				addressCountry: get(address, 'country'),
				addressLocality: get(address, 'locality'),
				addressRegion: get(address, 'region'),
				postalCode: get(address, 'postcode'),
				streetAddress: get(address, 'street'),
			});
		});
	}

	return store[0];
};

/**
 * Some description...
 *
 * @param contactPoints
 */
const getContactPoint = (contactPoints: Array<object>) => {
	const store = [];

	if (contactPoints !== undefined) {
		forEach(contactPoints, contactPoint => {
			store.push({
				telephone: get(contactPoint, 'phone'),
			});
		});
	}

	if (store.length) {
		return store[0].telephone;
	} else {
		return '';
	}
};

/**
 * Some description...
 *
 * @param locations
 */
const getLocation = (locations: Array<object>) => {
	const store = [];

	if (locations !== undefined) {
		forEach(locations, location => {
			store.push({
				latitude: get(location, 'lat'),
				longitude: get(location, 'lng'),
			});
		});
	}

	return store[0] || null;
};

/**
 * Some description...
 *
 * @param openingHours
 */
const getOpeningHours = (openingHours: Array<object>) => {
	const store = [];

	if (openingHours !== undefined) {
		forEach(openingHours, specification => {
			forEach(get(specification, 'openings'), opening => {
				store.push({
					closes: format(get(opening, 'close'), 'HH:MM', {
						locale: enGB,
					}),
					dayOfWeek: get(opening, 'days'),
					opens: format(get(opening, 'open'), 'HH:MM', {
						locale: enGB,
					}),
				});
			});
		});
	}

	return store;
};

/**
 * Some description...
 *
 * @param platforms
 */
const getPlatforms = (platforms: Array<object>) => {
	const store = [];

	if (platforms !== undefined) {
		forEach(platforms, platform => {
			store.push(get(platform, 'url'));
		});
	}

	return store;
};

export {
	getAddress,
	getContactPoint,
	getLocation,
	getOpeningHours,
	getPlatforms,
};
