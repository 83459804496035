/*
 |-----------------------------------------------------------------------------
 | components/vendor/Aos/index.ts
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

export { default } from './Aos';
