/*
 |-----------------------------------------------------------------------------
 | components/layouts/Layout/Layout.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useEffect } from 'react';

import { DefaultSeo, LocalBusinessJsonLd } from 'next-seo';
import { get, isEmpty } from 'lodash';
import dynamic from 'next/dynamic';
// import { ray } from 'node-ray/web';
import { useRouter } from 'next/router';

import * as schema from '@/utils/schema';
import { menuSelector, toggleMenu } from '@/features/menu';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import SkipLink from '@/molecules/SkipLink';
import { collectionSelector } from '@/features/collection';
import { fallback } from '@/utils/helpers';
import { organisationSelector } from '@/features/organisation';
import { pageSelector } from '@/features/page';
import { transform } from '@/utils/images';

import * as ILayout from './types';

const Footer = dynamic(() => import('@/organisms/Footer'));
const Header = dynamic(() => import('@/organisms/Header'));

const Layout: FC<ILayout.IProps> = ({ children, hideFooter, hideHeader }) => {
	// ray('Debug layout Layout', {
	// 	hideFooter: hideFooter,
	// 	hideHeader: hideHeader,
	// }).orange();

	const router = useRouter();

	const dispatch = useAppDispatch();

	const collection = useAppSelector(collectionSelector);
	const menuState = useAppSelector(menuSelector);
	const organisation = useAppSelector(organisationSelector);
	const page = useAppSelector(pageSelector);

	useEffect(() => {
		dispatch(toggleMenu(false));
	}, [page]);

	let openGraph = {};
	let twitterCard = {};
	if (!isEmpty(get(page, ['meta', 'openGraph']))) {
		openGraph = {
			type: 'website',
			url: process.env.NEXT_PUBLIC_SITE_URL + get(router, 'asPath'),
			title:
				get(page, ['meta', 'openGraph', 'title']) ||
				get(page, ['meta', 'title']) ||
				get(page, 'title'),
			description:
				get(page, ['meta', 'openGraph', 'description']) ||
				get(page, ['meta', 'description']),
			images: [
				{
					alt: get(page, ['meta', 'openGraph', 'image', 'alt']),
					height: 628,
					url: transform(
						get(page, ['meta', 'openGraph', 'image', 'src']),
						'c_crop,h_628,w_1200'
					),
					width: 1200,
				},
			],
		};

		twitterCard = {
			cardType: 'summary',
		};
	}

	let anthology, darkMode;
	switch (collection) {
		case 'Article':
			anthology = ` · Articles `;
			darkMode = false;
			break;
		case 'Events':
			anthology = ` · Events `;
			darkMode = false;
			break;
		case 'Gallery':
			anthology = ` · Galleries `;
			darkMode = false;
			break;
		case 'Job':
			anthology = ` · Jobs `;
			darkMode = false;
			break;
		case 'Person':
			anthology = ` · People `;
			darkMode = false;
			break;
		case 'Post':
			anthology = ` · Posts `;
			darkMode = false;
			break;
		case 'Product':
			anthology = ` · Products `;
			darkMode = false;
			break;
		case 'Project':
			anthology = ` · Projects `;
			darkMode = false;
			break;
		case 'Recipe':
			anthology = ` · Recipes `;
			darkMode = false;
			break;
		case 'Service':
			anthology = ` · Services `;
			darkMode = true;
			break;
		default:
			switch (get(router, 'asPath')) {
				case '/privacy-notice':
				case '/terms-and-conditions':
					anthology = ' ';
					darkMode = true;
					break;
				default:
					anthology = ' ';
					darkMode = false;
			}
	}

	return (
		<>
			{process.env.NEXT_PUBLIC_ENV !== 'production' ? (
				<DefaultSeo
					title={fallback(
						get(page, ['meta', 'title']),
						get(page, 'title')
					)}
					titleTemplate={`%s${anthology} | Private hospital in Wales | Sana`}
					description={get(page, ['meta', 'description'])}
					dangerouslySetAllPagesToNoIndex
					dangerouslySetAllPagesToNoFollow
					openGraph={openGraph}
					twitter={twitterCard}
				/>
			) : (
				<DefaultSeo
					title={fallback(
						get(page, ['meta', 'title']),
						get(page, 'title')
					)}
					titleTemplate={`%s${anthology} | Sana`}
					description={get(page, ['meta', 'description'])}
					noindex={get(page, ['meta', 'robots'])} // TODO: Confirm
					nofollow={get(page, ['meta', 'robots'])} // TODO: Confirm
					openGraph={openGraph}
					twitter={twitterCard}
				/>
			)}

			{get(organisation, 'type') && (
				<LocalBusinessJsonLd
					// Required props
					address={schema.getAddress(get(organisation, 'addresses'))}
					id={process.env.NEXT_PUBLIC_SITE_URL}
					name={get(organisation, 'name')}
					type={get(organisation, 'type')}
					// Optional props
					description={get(organisation, 'description')}
					geo={schema.getLocation(get(organisation, 'locations'))}
					images={[
						transform(get(organisation, 'image'), 'h_1080,w_1920'),
						transform(get(organisation, 'image'), 'h_90,w_160'),
					]}
					openingHours={schema.getOpeningHours(
						get(organisation, 'openingHours')
					)}
					sameAs={schema.getPlatforms(get(organisation, 'platforms'))}
					telephone={schema.getContactPoint(
						get(organisation, 'contactPoints')
					)}
					url={
						process.env.NEXT_PUBLIC_SITE_URL + get(router, 'asPath')
					}
				/>
			)}

			<SkipLink darkMode={darkMode} />

			{process.env.NEXT_PUBLIC_SNIPCART_API_KEY && (
				<div
					hidden
					id="snipcart"
					data-api-key={process.env.NEXT_PUBLIC_SNIPCART_API_KEY}
				></div>
			)}

			{/*<div*/}
			{/*	className={`*/}
			{/*		flex*/}
			{/*		flex-col*/}
			{/*		min-h-screen*/}
			{/*		relative*/}
			{/*		selection:bg-fuchsia-300*/}
			{/*		selection:text-fuchsia-900*/}
			{/*		after:fixed*/}
			{/*		after:bg-black*/}
			{/*		after:bg-opacity-75*/}
			{/*		after:inset-0*/}
			{/*		after:transition-all*/}
			{/*		${*/}
			{/*			menuState*/}
			{/*				? 'after:opacity-100 after:visible'*/}
			{/*				: 'after:invisible after:opacity-0'*/}
			{/*		}*/}
			{/*	`}*/}
			{/*>*/}
			<div
				className={`
					flex
					flex-col
					min-h-screen
					relative
					selection:bg-brand-1
					selection:text-brand-4
				`}
			>
				{!hideHeader && <Header darkMode={darkMode} />}

				<main
					className={`grow`}
					id="main-content"
					itemProp="mainContentOfPage"
					tabIndex={-1}
				>
					{children}
				</main>

				{!hideFooter && <Footer darkMode={darkMode} />}
			</div>
		</>
	);
};

export default Layout;
