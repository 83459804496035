/*
 |-----------------------------------------------------------------------------
 | utils/data.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { get, omit } from 'lodash';

import { setArticles } from '@/features/articles';
import { setCertifications } from '@/features/certifications';
import { setCollection } from '@/features/collection';
import { setEvents } from '@/features/events';
import { setJobs } from '@/features/jobs';
import { setMenus } from '@/features/menus';
import { setOrganisation } from '@/features/organisation';
import { setPage } from '@/features/page';
import { setPatrons } from '@/features/patrons';
import { setPeople } from '@/features/people';
import { setPosts } from '@/features/posts';
import { setProducts } from '@/features/products';
import { setProjects } from '@/features/projects';
import { setRecipes } from '@/features/recipes';
import { setReviews } from '@/features/reviews';
import { setServices } from '@/features/services';
import { setStockists } from '@/features/stockists';

/**
 * Some description...
 *
 * @param collectionName,
 * @param collections
 * @param dispatch
 * @param menus
 * @param organisation
 * @param page
 */
const setState = (
	collectionName: string,
	collections: object,
	dispatch,
	menus: object,
	organisation: object,
	page: object
) => {
	dispatch(setCollection(collectionName));
	dispatch(setMenus(get(menus, 'edges')));
	dispatch(setOrganisation(organisation));
	dispatch(setPage(omit(page, 'blocks')));

	if (collections) {
		Object.keys(collections).forEach(collection => {
			switch (collection) {
				case 'articles':
					dispatch(setArticles(get(collections, collection)));
					break;
				case 'certifications':
					dispatch(setCertifications(get(collections, collection)));
					break;
				case 'events':
					dispatch(setEvents(get(collections, collection)));
					break;
				case 'jobs':
					dispatch(setJobs(get(collections, collection)));
					break;
				case 'patrons':
					dispatch(setPatrons(get(collections, collection)));
					break;
				case 'people':
					dispatch(setPeople(get(collections, collection)));
					break;
				case 'posts':
					dispatch(setPosts(get(collections, collection)));
					break;
				case 'products':
					dispatch(setProducts(get(collections, collection)));
					break;
				case 'projects':
					dispatch(setProjects(get(collections, collection)));
					break;
				case 'recipes':
					dispatch(setRecipes(get(collections, collection)));
					break;
				case 'reviews':
					dispatch(setReviews(get(collections, collection)));
					break;
				case 'services':
					dispatch(setServices(get(collections, collection)));
					break;
				case 'stockists':
					dispatch(setStockists(get(collections, collection)));
					break;
			}
		});
	}
};

export { setState };
