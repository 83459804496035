/*
 |-----------------------------------------------------------------------------
 | utils/helpers.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { enGB } from 'date-fns/locale';
import { format } from 'date-fns';

/**
 * Some description...
 *
 * @param code
 */
const country = (code: string) => {
	const countries = {
		AF: 'Afghanistan',
		AX: 'Aland Islands',
		AL: 'Albania',
		DZ: 'Algeria',
		AS: 'American Samoa',
		AD: 'Andorra',
		GB: 'United Kingdom',
		US: 'United States',
	};

	return countries[code];
};

/**
 * Some description..
 *
 * @param primary
 * @param secondary
 */
const fallback = (primary: object | string, secondary: object | string) => {
	let item;

	if (!primary) {
		item = secondary;
	} else {
		item = primary;
	}

	return item;
};

/**
 * Some description...
 *
 * @param year
 */
const now = (year: number) => {
	const now = format(new Date(), 'Y', { locale: enGB });

	return Number(now) > year ? `${year}-${now}` : now;
};

/**
 * Some description...
 *
 * @param url
 */
const pageView = (url) => {
	window.dataLayer.push({
		event: 'pageview',
		page: url,
	});
};

export { country, fallback, now, pageView };
